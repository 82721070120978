import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, A11y } from "swiper";

import React, { useState, useEffect, useRef } from "react";

import "swiper/css/bundle";
import "swiper/css/effect-fade";

import ImageWrapper from "./ImageWrapper";
import { EffectFade } from "swiper";

import Aos from "aos";
import "aos/dist/aos.css";

import { useRouter } from 'next/router';

function CmsSwiperIntro({ component, page_id }) {
  component.options.time = 5000;
  component.options.speed = 1000;

  const [swiper, setSwiper] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const dynamicRoute = useRouter().asPath;

  useEffect(() => {
    setIsClient(false);
    // console.log(swiper);
    if (swiper !== null) {
        // swiper.destroy()
        swiper.init(swiper)
        swiper.slideToLoop(0, 1)
        swiper.update();
        // swiper.pagination.update()
        // console.log("swiperUpdate");
      }
  }, [dynamicRoute, swiper]);

  return (
    <div id={`${component.id ?? ""}`}>
          {/* {isClient && ( */}
            <Swiper
              id={`swiperintro_${page_id ?? ''}_${component.id ?? ""}`}
              modules={[Autoplay, Navigation, Pagination, EffectFade, A11y]}
              spaceBetween={0}
              autoplay={{ 
                delay: 3000,
                disableOnInteraction: false 
              }}
              speed={ 1000 }
              autoHeight={false}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: component.options.slidesPerView,
                  spaceBetween: 0
                }
              }}
              pagination={{
                el: ".custom-pagination-intro",
                clickable: true,
              }}
              loop={component.options.slides.length > 1 ? true : false}
              onSwiper={setSwiper}
            >
              {component.options.slides.map(function (slide, i) {
                var imageStyle =
                  typeof slide.image.url != ""
                    ? {
                        backgroundImage: `url(${slide.image.url})`,
                      }
                    : {};

                return (
                  <SwiperSlide
                    key={i}
                    className={`slide`}
                    // style={imageStyle}
                  >
                    <ImageWrapper
                      alt=""
                      src={slide.image.url}
                      blur={slide.image.urlp}
                      priority={i==0}
                      layout="fill"
                      objectFit="cover"
                      quality={100}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          {/* )} */}
          <div className={`captionWrapper`}>
            <div className={`captionContainer`}>
              <div className="caption">
                <div dangerouslySetInnerHTML={{
                  __html: component.options.slides[0].caption,
                }} />
                <div className="custom-pagination-intro"></div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default CmsSwiperIntro;
